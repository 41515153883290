<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">警情事发地</div>
    <div class="idx-middle-2" id="ttBox" @click="go"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "sftj",
        dataTyp: "sftj_xjly",
      },
      queryParams1: {
        dataModule: "msjq",
        dataTyp: "msjq_jqsfd",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i] = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);
      this.dataOptions = response.rows
      this.shijianFs();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "警情事发地", arr);
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("ttBox"));
        // 常住人口
        //   let dom = document.getElementById("ttBox");
        //   let myChart = echarts.init(dom);
        let app = {};
        //   let option = null;
        let option = {
          color: ["#3398DB"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          // // 东泰花园 景湖蓝郡 景湖名郡 民盈国贸 凯旋城
          // 景湖春晓 景湖春天 光大花园 万科城市花园 景湖花园
          // 锦绣旗峰 阳光澳园 阳光假日 黄旗印象 九龙一号
          // 卡布斯 德方斯 协和大厦 汇业写字楼 其他

          //         东泰花园 阳光澳园 阳光假日 景湖春天 景湖春晓
          // 景湖名郡 景湖蓝郡 德方斯 凯旋城 顶峰国际广场
          // 中信商业广场 民盈国贸城 黄旗印象 锦绣旗峰 景湖花园
          // 其他地方
          xAxis: [
            {
              type: "category",
              data: this.xdata, // ["东泰花园", "阳光澳园", "阳光假日", "景湖春天", "景湖春晓", "景湖名郡", "景湖蓝郡", "德方斯", "凯旋城", "顶峰国际广场", "中信商业广场", "民盈国贸城", "黄旗印象", "锦绣旗峰", "景湖花园", "其他地方"],
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                color: "#fff",
                fontStyle: "normal",
                interval: 0,
                fontSize: 12,
              },
              //设置轴线的属性
              axisLine: {
                lineStyle: {
                  color: "#0079FF",
                  width: 1, //这里是为了突出显示加上的
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                color: "#fff",
                fontStyle: "normal",
                fontSize: 14,
              },
              //设置轴线的属性
              axisLine: {
                lineStyle: {
                  color: "#0079FF",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              splitLine: {
                //网格线
                lineStyle: {
                  color: "#666",
                  type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true, //隐藏或显示
              },
            },
          ],
          // 149, 50 ,54, 45 ,50,39, 38 ,39 ,33, 12,12, 13 ,14 ,7 ,1,2, 1, 1 ,1 ,20
          // 110, 2, 16, 52, 35 ,14, 8 ,1, 9, 12,3 ,51 ,13, 4, 19,107
          series: [
            {
              name: "",
              type: "bar",
              barWidth: "30%",
              data: this.ydata, //[110, 2, 16, 52, 35, 14, 8, 1, 9, 12, 3, 51, 13, 4, 19, 107],
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: "top", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "#fff",
                      fontSize: 14,
                    },
                  },
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 1, color: "#83B1FF" }, //柱图渐变色
                    { offset: 0.5, color: "#0086FF" }, //柱图渐变色
                    { offset: 0, color: "#0086FF" }, //柱图渐变色
                  ]),
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("ttBox"));
        // 常住人口
        //   let dom = document.getElementById("ttBox");
        //   let myChart = echarts.init(dom);
        let app = {};
        //   let option = null;
        let option = {
          color: ["#3398DB"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          // // 东泰花园 景湖蓝郡 景湖名郡 民盈国贸 凯旋城
          // 景湖春晓 景湖春天 光大花园 万科城市花园 景湖花园
          // 锦绣旗峰 阳光澳园 阳光假日 黄旗印象 九龙一号
          // 卡布斯 德方斯 协和大厦 汇业写字楼 其他

          //         东泰花园 阳光澳园 阳光假日 景湖春天 景湖春晓
          // 景湖名郡 景湖蓝郡 德方斯 凯旋城 顶峰国际广场
          // 中信商业广场 民盈国贸城 黄旗印象 锦绣旗峰 景湖花园
          // 其他地方
          xAxis: [
            {
              type: "category",
              data: this.xdata, // ["东泰花园", "阳光澳园", "阳光假日", "景湖春天", "景湖春晓", "景湖名郡", "景湖蓝郡", "德方斯", "凯旋城", "顶峰国际广场", "中信商业广场", "民盈国贸城", "黄旗印象", "锦绣旗峰", "景湖花园", "其他地方"],
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                color: "#fff",
                fontStyle: "normal",
                interval: 0,
                fontSize: 14,
              },
              //设置轴线的属性
              axisLine: {
                lineStyle: {
                  color: "#0079FF",
                  width: 1, //这里是为了突出显示加上的
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                color: "#fff",
                fontStyle: "normal",
                fontSize: 14,
              },
              //设置轴线的属性
              axisLine: {
                lineStyle: {
                  color: "#0079FF",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              splitLine: {
                //网格线
                lineStyle: {
                  color: "#666",
                  type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true, //隐藏或显示
              },
            },
          ],
          // 149, 50 ,54, 45 ,50,39, 38 ,39 ,33, 12,12, 13 ,14 ,7 ,1,2, 1, 1 ,1 ,20
          // 110, 2, 16, 52, 35 ,14, 8 ,1, 9, 12,3 ,51 ,13, 4, 19,107
          series: [
            {
              name: "",
              type: "bar",
              barWidth: "30%",
              data: this.ydata, //[110, 2, 16, 52, 35, 14, 8, 1, 9, 12, 3, 51, 13, 4, 19, 107],
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: "top", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "#fff",
                      fontSize: 14,
                    },
                  },
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 1, color: "#83B1FF" }, //柱图渐变色
                    { offset: 0.5, color: "#0086FF" }, //柱图渐变色
                    { offset: 0, color: "#0086FF" }, //柱图渐变色
                  ]),
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 33vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    width: 100%;
    height: 33vh;
    overflow: hidden;
    position: absolute;
    top: -3vh;
    left: 0vw;
    right: 0vw;
  }
}
</style>

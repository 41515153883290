<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img class="back-ground-image" src="@/assets/image/idx-jqing.png" alt="" />
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <!-- top -->
      <div class="renkou-top">
        <div class="renkou-top-L">
          <!-- 警务分析 -->
          <JingwuFx @dialog_click="dialog_click"></JingwuFx>
          <!-- 警务分析弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        </div>
        <div class="renkou-top-M">
          <!-- 民生警情 -->
          <MingsJqing @dialog_click="dialog_click"></MingsJqing>
          <!-- 民生警情弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        </div>
        <div class="renkou-top-R">
          <!-- 数量同比 -->
          <NumTb @dialog_click="dialog_click"></NumTb>
          <!-- 数量同比弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        </div>
      </div>
      <!-- bottom -->
      <div class="renkou-bottom">
        <!-- 警情发生地 -->
        <EventsPlace @dialog_click="dialog_click"></EventsPlace>
        <!-- 警情发生地弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/dialog/dialog.vue";
import Demo from "@/views/mingshengJq/components/demo.vue";
import Date from "@/views/commonComponents/date.vue";
import NumTb from "@/views/mingshengJq/components/numTb.vue";
import JingwuFx from "@/views/mingshengJq/components/jingwuFx.vue";
import MingsJqing from "@/views/mingshengJq/components/mingsJqing.vue";
import EventsPlace from "@/views/mingshengJq/components/eventsPlace.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";
export default {
  components: {
    Demo,
    Date,
    EventsPlace,
    NumTb,
    JingwuFx,
    MingsJqing,
    rightNav,
    Dialog
  },
  data() {
    return {
      edit_dialog: false,
      dialogTitle: '',
      dataList: [],  // 传递数据
    };
  },
  created() {},
  methods: {
    // 弹框出现
    dialog_click(t,e) {
      console.log("实际数据", e);
      if(!this.existSystem()){
        return false;
      }
      this.dialogTitle = t,
      this.dataList = e;
      this.edit_dialog = true;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  .time-box {
    width: 25vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 7vh;
    right: 3vw;
    z-index: 99;
  }
  // top
  .renkou-top {
    width: 100vw;
    height: 51vh;
    // border: 1px solid tomato;
    .renkou-top-L {
      width: 22.5vw;
      height: 51vh;
      //   border: 1px solid tan;
      margin-left: 2vw;
      float: left;
    }
    .renkou-top-M {
      width: 41.5vw;
      height: 45vh;
      //   border: 1px solid tan;
      margin-left: 4.3vw;
      margin-top: 6vh;
      float: left;
    }
    .renkou-top-R {
      width: 22.5vw;
      height: 45vh;
      float: left;
      margin-left: 4.5vw;
      //   border: 1px solid tan;
      margin-top: 6vh;
    }
  }
  //   bottom
  .renkou-bottom {
    width: 100vw;
    height: 31vh;
    // border: 1px solid tomato;
    margin-top: 6vh;
    position: relative;
  }
}
</style>

<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">民生警情</div>
    <div @click="go">
      <div class="idx-middle-2">
      <div class="idx-middle-2box">
        <span>{{ getdatavalue(200) }}</span>
        <span>全部事件</span>
      </div>
      <div class="idx-middle-2box">
        <span>{{ getdatavalue(202) }}</span>
        <span>已处理</span>
      </div>
      <div class="idx-middle-2box">
        <span>{{ getdatavalue(203) }}</span>
        <span>处理中</span>
      </div>
      <div class="idx-middle-2box">
        <span>{{ getdatavalue(204) }}</span>
        <span>未批理</span>
      </div>
      <div class="idx-middle-2box">
        <span>{{ getdatavalue(205) }}%</span>
        <span>处理率</span>
      </div>
    </div>
    <div class="idx-middle-3">
      <div class="idx-middle-3-L">
        <div class="idx-middle-3-Lbox ms-bg1">
          <span>{{ getdatavalue(314) }}</span>
          <span>盗窃案件</span>
        </div>
        <div class="idx-middle-3-Lbox ms-bg2">
          <span>{{ getdatavalue(315) }}</span>
          <span>诈骗案件</span>
        </div>
        <div class="idx-middle-3-Lbox ms-bg3">
          <span>{{ getdatavalue(316) }}</span>
          <span>公民求助</span>
        </div>
      </div>
      <div class="idx-middle-3-R" id="msjqing"></div>
    </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "msjq",
        dataTyp: "msjq_msjqhz",
      },
      queryParams1: {
        dataModule: "msjq",
        dataTyp: "msjq_msjqmx",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);

      this.shijianFs();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "民生警情", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("msjqing"));
        let color = ["#A1A701", "#01689B", "#6D4271"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          // legend: {
          //   orient: "vertical",
          //   left: "left",
          //   data: ["直接访问", "邮件营销", "联盟广告", "视频广告", "搜索引擎"],
          // },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: ["55%", "70%"],
              center: ["45%", "50%"],
              data: this.ydata,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("msjqing"));
        let color = ["#A1A701", "#01689B", "#6D4271"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          // legend: {
          //   orient: "vertical",
          //   left: "left",
          //   data: ["直接访问", "邮件营销", "联盟广告", "视频广告", "搜索引擎"],
          // },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: ["55%", "70%"],
              center: ["40%", "50%"],
              data: this.ydata,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    width: 100%;
    height: 10vh;
    overflow: hidden;
    // border: 1px solid tan;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .idx-middle-2box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 2.8vh;
      span {
        &:first-child {
          font-size: 1.2vw;
          font-family: hy;
          font-weight: bold;
          font-style: italic;
          color: #ffd901;
        }
        &:last-child {
          font-size: 0.8vw;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #eeab1a;
        }
      }
    }
  }
  .idx-middle-3 {
    width: 100%;
    height: 29vh;
    overflow: hidden;
    // border: 1px solid tan;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // align-items: center;
    .idx-middle-3-L {
      width: 20%;
      height: 29vh;
      overflow: hidden;
      //   border: 1px solid tan;
      float: left;
      color: #fff;

      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-around;
      //   align-items: center;
      .idx-middle-3-Lbox {
        width: 62%;
        height: 7vh;
        line-height: 0vh;
        margin: 0 auto;
        margin-bottom: 2vh;
        margin-left: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        span {
          font-size: 0.9vw;
          &:first-child {
            width: 100%;
            text-align: left;
            font-size: 0.8vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #ffffff;
            text-indent: 1vw;
          }
        }
      }
      .ms-bg1 {
        background: #a1a701;
      }
      .ms-bg2 {
        background: #01689b;
      }
      .ms-bg3 {
        background: #6d4271;
      }
    }
    .idx-middle-3-R {
      width: 80%;
      height: 29vh;
      overflow: hidden;
      // border: 1px solid tan;
      float: left;
    }
  }
}
</style>

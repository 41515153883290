<template>
  <div class="jw-left-box3">
    <div class="jw-left-1">警务分析</div>
    <div @click="go">
      <div class="jw-left-2">
      <div class="jw-left-2box">
        <div class="jw-left-2box-1">宣传到户</div>
        <div class="jw-left-2box-2">
          <span>{{ getdatavalue(195) }}</span>
          <span>户</span>
        </div>
      </div>
      <div class="jw-left-2box">
        <div class="jw-left-2box-1">宣传到人</div>
        <div class="jw-left-2box-2">
          <span>{{ getdatavalue(196) }}</span>
          <span>人</span>
        </div>
      </div>
    </div>
    <div class="jw-left-3">
      <div class="jw-left-3-title">宣传种类</div>
      <div class="jw-left-3box">
        <ul>
          <li>
            <span>电信诈骗</span>
            <span>{{ getdatavalue(197) }}+</span>
          </li>
          <li>
            <span>扫黑除恶</span>
            <span>{{ getdatavalue(198) }}+</span>
          </li>
          <li>
            <span>”双提升“</span>
            <span>{{ getdatavalue(199) }}+</span>
          </li>
        </ul>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "msjq",
        dataTyp: "msjq_jwfx",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "警务分析", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
};
</script>

<style scoped lang="scss">
.jw-left-box3 {
  width: 100%;
  height: 51vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .jw-left-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .jw-left-2 {
    width: 100%;
    height: 9vh;
    // border: 1px solid tan;
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .jw-left-2box {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 3vh;
      margin-left: -4vw;
      .jw-left-2box-1 {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
      }
      .jw-left-2box-2 {
        span {
          &:first-child {
            font-size: 1.2vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #eeab1a;
            padding-right: 0.3vw;
          }
          &:last-child {
            font-size: 0.7vw;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffd901;
          }
        }
      }
    }
  }
  .jw-left-3 {
    width: 85%;
    height: 31vh;
    // border: 1px solid tan;
    margin: 0 auto;
    margin-left: 1.9vw;
    .jw-left-3-title {
      width: 100%;
      height: 5vh;
      line-height: 5vh;
      //   border: 1px solid tan;
      font-size: 0.8vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #04f3ee;
    }
    .jw-left-3box {
      ul li {
        width: 93%;
        margin: 0 auto;
        height: 7vh;
        line-height: 7vh;
        margin-bottom: 1.3vh;
        // border: 1px solid tan;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
          &:first-child {
            font-size: 0.8vw;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
          }
          &:last-child {
            font-size: 1.2vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #eeab1a;
          }
        }
      }
    }
  }
}
</style>
